import { atom, RecoilState, useRecoilValue } from "recoil";
//import { recoilPersist } from "recoil-persist";
import { getLanguage } from "../features/language";

interface LanguageState {
    preferredLanguage: "en"
};

//const { persistAtom } = recoilPersist();

const languageState: RecoilState<LanguageState> = atom({
    key: "language",
    default: {preferredLanguage: "en"},
    //effects_UNSTABLE: [persistAtom]
});

export function useLanguage() {
    return getLanguage(useRecoilValue(languageState).preferredLanguage);
};