import { Box, Button } from "@mui/material";
import { Route, routes } from "../../features/router/routes";
import { useLanguage } from "../../state/language";
import Logo from "../logo/Logo";
import { useNavigate } from "react-router-dom";

interface HeaderLargeProps {
    closeMenu: () => void;
};

const styles = {
    container: {
        justifyContent: "space-between",
        alignItems: "center",
        display: {md: "flex", xs: "none"},
        flexDirection: "row",
        width: "100%",
        paddingTop: 2
    }
}

export default function HeaderLarge(props: HeaderLargeProps) {
    const language = useLanguage();
    const navigate = useNavigate();
    
    return <Box sx={styles.container}>
        <Box><Logo /></Box>

        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        {
            routes.map((route: Route) => (
            <Button
                key={route.name}
                onClick={() => {props.closeMenu(); navigate(route.path)}}
                sx={{ my: 2, color: 'white', display: 'block' }}
            >
                {language.menu[route.name]}
            </Button>
            ))
        }
        </Box>
    </Box>
}