import { Route, HashRouter, Routes, Navigate, useLocation, useMatch } from 'react-router-dom';
import {Route as PageRoute, routes} from './routes';
import { ServicesPage } from '../services';
import { useEffect } from 'react';

const ScrollToTop = (props: any) => {
    const location = useLocation();
    const match = useMatch("services/:service");

    useEffect(() => {
        if (!match?.params.service) {
            window.scrollTo(0, 0);
        }
    }, [location, match?.params.service]);
  
    return <>{props.children}</>
  };
  
const Router = () => {
    return <HashRouter>
        <ScrollToTop>
            <Routes>
                {
                    routes.map((route: PageRoute) => {
                        return <Route key={route.name} path={route.path} element={route.element} />
                    })
                }
                <Route path={"services/:service"} element={<ServicesPage />} />
                <Route path={"*"} element={<Navigate to="/"/>} />
            </Routes>
        </ScrollToTop>
    </HashRouter>
}


export default Router;