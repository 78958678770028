import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { useLanguage } from "../../../state/language";
import Services from "../../home/components/Services";
import Service from "../components/Service";
import useServices from "../data/services";

interface ServicesPageProps {};

export default function ServicesPage(props: ServicesPageProps) {
    const language = useLanguage();
    const services = useServices();
    return <>
        <Header coverText={language.menu.services} home={false}/>
        <Services />
        {
            services.map((service: any) => {
                return <Service {...service} key={service.name}/>
            })
        }
        <Footer />
    </>
};
