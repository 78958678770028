import { MouseEvent } from "react";
import { Box, IconButton, Menu, MenuItem, Theme, Typography, useTheme } from "@mui/material";
import { Route, routes } from "../../features/router/routes";
import { useLanguage } from "../../state/language";
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";

interface HeaderSmallProps {
    menuAnchor: Element | null
    openMenu: (event: MouseEvent<HTMLElement>) => void
    closeMenu: () => void
};

const makeStyles = (theme: Theme) => {
    return {
        logo: {
            color: theme.palette.primary.light,
            fontWeight: "bold",
            textAlign: "center",
            width: "100%",
            fontSize: 22
        },
        boxHeader: {
            marginRight: 3, 
            display: { xs: 'flex', md: 'none' },
        },
        sticky: {
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: theme.palette.primary.main,
            zIndex: 10000
        },
        menuItem: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            minWidth: "50vw",
            zIndex: 10000
        },
        icon: {
            marginRight: 3
        }
    }
}

export default function HeaderSmall(props: HeaderSmallProps) {
    const theme = useTheme();
    const language = useLanguage();
    const navigate = useNavigate();
    const styles = makeStyles(theme);

    return <Box sx={styles.sticky}>
        <Box sx={styles.boxHeader}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={props.openMenu}
                color="inherit"
            >
                <MenuIcon />
            </IconButton>
            <Menu
                id="menu-appbar"
                anchorEl={props.menuAnchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(props.menuAnchor)}
                onClose={props.closeMenu}
                sx={{
                    display: { xs: 'block', md: 'none', zIndex: 10000 },
                }}
            >
                {
                routes.map(
                    (route: Route) => (
                        <MenuItem key={route.name} onClick={() => {
                            props.closeMenu();
                            navigate(route.path);
                        }}>
                            <Box sx={styles.menuItem}>
                                <Box><route.icon sx={styles.icon}/></Box>
                                <Typography textAlign="center">{language.menu[route.name]}</Typography>
                            </Box>
                        </MenuItem>
                    ))
                }
            </Menu>
        </Box>

        <Box sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 9 }}>
            <Typography sx={styles.logo}>{language.menu.leatrop}</Typography>
        </Box>
    </Box>
}