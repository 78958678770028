import { Box, List, ListItem, ListItemIcon, Theme, Typography, useTheme } from "@mui/material"
import { useLanguage } from "../../../state/language";
import { DownloadDone } from "@mui/icons-material";

interface CompetencesProps {

};

const makeStyles = (theme: Theme) => {
    return {
        box: {},
        title: {
            fontSize: 32
        },
        listItem: {
            py: 2
        },
        itemText: {
            fontSize: 18
        }
    }
}
export default function Competences(props: CompetencesProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <Box sx={styles.box} px={{xs: 2, md: 8, lg: 48}}>
        <Typography sx={styles.title}>{language.about.competences.title}</Typography>
        <List>
        {
            language.about.competences.items.map((item: string, index: number) => {
                return <ListItem key={index.toString()} sx={styles.listItem}>
                    <ListItemIcon>
                        <DownloadDone color={"primary"}/>
                    </ListItemIcon>
                    <Typography sx={styles.itemText}>{item}</Typography>
                </ListItem>
            })
        }
        </List>
        </Box>
}