
import { Box, Grid, IconProps, List, ListItem, Theme, Typography, useTheme } from "@mui/material";
import { Bullet } from "../../language/Language";
import CircleIcon from '@mui/icons-material/Circle';
import { useEffect, useRef } from "react";
import { useLocation, useMatch } from "react-router-dom";
import EastIcon from '@mui/icons-material/East';

interface ServiceProps {
    title: string
    short: string
    long?: string
    beforeSubtitle?: string
    subtitle1: string
    afterSubtitle?: string
    bullets: Array<Bullet>
    icon: (props: IconProps) => JSX.Element
    sidePhoto: string
    reverse?: boolean
    name: "changeManagement" | "procurement" | "sustainability"
}

const makeStyles = (theme: Theme, reverse: boolean) => {
    return {
        box: {},
        bannerBasic: {
            alignItems: "center"
        },
        coverBox: {
            width: "100%",
            height: "100%",
            backgroundSize: "cover",
            borderRadius: 10
        },
        textWithPhoto: {width: "100%", height: "100"},
        banner: reverse ? {
            pr: {
                xs: 2,
                md: 12,
            },
            pl: {
                xs: 2,
                md: 12
            },
            backgroundColor: theme.palette.primary.dark,
            position: "relative",
        } : {
            pl: {
                xs: 2,
                md: 12
            },
            pr: {
                xs: 2,
                md: 12
            },
        },
        container: {
            backgroundColor: reverse ? null : theme.palette.primary.light,
            color: reverse ? theme.palette.primary.light : theme.palette.primary.dark,
            p: 4,
            borderRadius: 12,
            justifyContent: "space-between"
        },
        textItem: {
            p: 0
        },
        title: {
            fontSize: 32,
            ml: 3
        },
        titleBox: {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        short: {
            my: 2,
            whiteSpace: "pre-line"
        },
        subtitle: {
            fontSize: 24,
            width: "100%",
            marginTop: 6,
            marginBottom: 4
        },
        bullets: {
        },
        bullet: {
            fontSize: 8,
            marginRight: 2,
        },
        bulletIndent: {
            marginLeft: 6,
            fontSize: 12,
        },
        list: {
            alignContent: "flex-start",
            alignItems: "center",
        },
        iconButton: {
            width: "100%",
            cursor: "auto",
            height: "100%"
        },
        icon: {
            width: "50%",
            height: "50%",
            color: reverse ? theme.palette.primary.light : theme.palette.primary.main
        }
    }
}

export default function Service(props: ServiceProps) {
    const ref: any = useRef(null);
    const theme = useTheme();
    const styles = makeStyles(theme, props.reverse ?? false);
    const match = useMatch("/services/:service")
    const location = useLocation();

    useEffect(() => {
        if (ref?.current && match?.params.service === props.name) {
            ref.current.scrollIntoView({behavior: "smooth"});
        }
    }, [ref, match?.params.service, props.name, location])

    const textBlock = <Grid item sx={styles.textItem} xs={12} md={6}>
        <Box sx={styles.titleBox}>< props.icon fontSize={"medium"} /> <Typography sx={styles.title}>{props.title}</Typography></Box>
        <Box>
            {props.beforeSubtitle ? <Typography sx={styles.short}>{props.beforeSubtitle}</Typography> : <></>}

            {props.name === "sustainability" ? <></> : <Typography sx={styles.short}>{props.short}</Typography>}
            {props.long ? <Typography sx={styles.short}>{props.long}</Typography> : <></>}


            <Typography sx={styles.subtitle}>{props.subtitle1}</Typography>
            <List sx={styles.list} disablePadding>
                {
                    props.bullets.map((value: Bullet) => {
                        return <Box key={value.title}>
                        <ListItem sx={styles.list}>
                            <CircleIcon sx={[styles.bullet]}/>
                            <Typography sx={styles.bullets}>{value.title}</Typography>
                        </ListItem>

                            <List disablePadding>
                                {
                                    value.items.map((item: string) => {
                                        return <ListItem sx={styles.list} key={item}>
                                            <EastIcon sx={[styles.bullet, styles.bulletIndent]}/>
                                            <Typography sx={styles.bullets}>
                                                {item}
                                            </Typography>
                                        </ListItem>
                                    })
                                }
                            </List>
                        </Box>
                    })
                }
            </List>

            {props.afterSubtitle ? <Typography sx={styles.short}>{props.afterSubtitle}</Typography> : <></>}

        </Box>
    </Grid>

    const logoBlock = <Grid item sx={styles.textItem} xs={12} md={4} >
        <Box sx={[styles.coverBox, {backgroundImage: `url(${require("../../../assets/" + props.sidePhoto)})`}]}></Box>
    </Grid>

    return <Box sx={styles.box}>
        <Box sx={[styles.banner, styles.bannerBasic]} py={{ xs: 2, md: 12 }} ref={ref}>
            <Grid container sx={styles.container} boxShadow={0} spacing={8}>
            {
                props.reverse ? <>{logoBlock}{textBlock}</> : 
                <>{textBlock}{logoBlock}</>}
            </Grid>
        </Box>
    </Box>
}