import { Box, useTheme, Theme, Typography, Divider, Grid, Button, Fade, IconButton } from "@mui/material"
import { useLanguage } from "../../../state/language"
import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import MultipleStopIcon from '@mui/icons-material/MultipleStop';
import ParkIcon from '@mui/icons-material/Park';

interface ServiceProps {
    name: "changeManagement" | "procurement" | "sustainability"
    image: string
    index: number
};

const makeStyles = (theme: Theme) => {
    return {
        gridItem: {
            marginBottom: 0,
            zIndex: 1000
        },
        box: {
            backgroundColor: "white",
            borderRadius: "25px",
            boxShadow: 2,
            flexDirection: "column",
            display: "flex",
        },
        textBox: {
            padding: 4,
            flexDirection: "column",
            display: "flex",
            justifyContent: "space-between",
            height: "100%"
        },
        boxTextIcon: {
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            minHeight: "100px"
        },
        coverBox: {
            display: "flex",
            height: "400px",
            width: "100%",
            backgroundSize: "cover",
            backgroundPosition: "center",
            borderRadius: "25px 25px 0 0",
            cursor: "pointer",
            '&:hover': {
              backgroundColor: 'primary.main',
              opacity: [0.9, 0.8, 0.7],
            },
        },
        cover: {
            objectFit: "cover",
        },
        icon: {
            marginRight: 3,
            backgroundColor: theme.palette.warning.main,
            color: theme.palette.primary.light,
            cursor: "auto"
        },
        buttonBox: {
            width: "100%",
            textAlign: "right",
            color: theme.palette.primary.dark,
        },
        dividerBox: {
            paddingY: 2,
            justifyContent: "center"
        },
        divider: {
            backgroundColor: theme.palette.warning.main
        },
        title: {
            fontSize: 24,
            fontStyle: "Roboto"
        },
        text: {
            fontSize: 18
        }
    }
}
export default function Service(props: ServiceProps) {
    const theme = useTheme();
    const language = useLanguage();
    const styles = makeStyles(theme);
    const navigate = useNavigate();
    const [fade, setFade] = useState(false);
    
    useEffect(() => {
        setTimeout(() => setFade(true), props.index * 300)
    }, [props.index]);

    const titleIcon = {
        1: (props: any) => <PublishedWithChangesIcon {...props} />,
        2: (props: any) => <MultipleStopIcon {...props} />,
        3: (props: any) => <ParkIcon {...props} />
    }[props.index] ?? PublishedWithChangesIcon;

    return <Grid item xs={12} md={12} lg={4} mb={8} sx={styles.gridItem}>
            <Fade in={fade} timeout={1000}>
                <Box height="90%" >
                    <Box sx={styles.box} height="100%">
                        <Box 
                            sx={[styles.coverBox, {
                                backgroundImage: `url(${require("../../../assets/" + props.image)})`
                            }]}
                            onClick={()=> {navigate("/services/" + props.name)}}
                        />
                        <Box sx={styles.textBox}>
                            <Box >
                                <Box sx={styles.boxTextIcon}>
                                    <IconButton sx={styles.icon} disableRipple disableFocusRipple>{titleIcon({})}</IconButton>
                                    <Typography sx={styles.title}>{language.services[props.name].title.toUpperCase()}</Typography>
                                </Box>
                                <Box sx={styles.dividerBox}><Divider sx={styles.divider}/></Box>
                                <Typography sx={styles.text}>{language.services[props.name].short}</Typography>
                            </Box>
                            <Box sx={styles.buttonBox}>
                                <Button variant="outlined" onClick={() => navigate("/services/" + props.name)}>{language.services.learnMore}</Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Grid>
}