import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { useLanguage } from "../../../state/language";
import About from "../components/About";
import Services from "../components/Services";
import ContactButton from "../components/ContactButton";
import { Box, Theme, useTheme } from "@mui/material";

interface HomePageProps {};

const makeStyles = (theme: Theme) => {
    return {
        page: {
            backgroundColor: theme.palette.secondary.main,
        }
    }
}
export default function HomePage(props: HomePageProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <Box sx={styles.page}>
        <Header coverText={language.home.intro} home>
            <ContactButton />
        </Header>
        <Services />
        <About />
        <Footer />
    </Box>
};