import { Call, Home, Person, SupportAgent } from "@mui/icons-material";
import { AboutPage } from "../about";
import { ContactPage } from "../contact";
import { HomePage } from "../home";
import Language from "../language/Language";
import { ServicesPage } from "../services";

interface Route {
    name: keyof Language["menu"]
    path: string
    element: JSX.Element
    icon: any
}

const routes: Array<Route> = [
    {
        name: "home",
        path: "/",
        element: <HomePage />,
        icon: Home
    },
    {
        name: "about",
        path: "/about",
        element: <AboutPage />,
        icon: Person
    },
    {
        name: "services",
        path: "/services",
        element: <ServicesPage />,
        icon: SupportAgent
    },
    {
        name: "contact",
        path: "/contact",
        element: <ContactPage />,
        icon: Call
    }
];

export type { Route };
export {
    routes
};