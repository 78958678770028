import { Theme, createTheme } from "@mui/material";

const theme: Theme = createTheme({
    palette: {
        primary: {
          main: "#066990",
          light: "#FFFFFF",
          dark: "#044964"
        },
        warning: {
          main: "#023346"
          //main: "#D6677D"
        },
        secondary: {
          main: "#EEFBFF"
        }
      },
});

export default theme;