import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { useLanguage } from "../../../state/language";
import ContactInfo from "../components/ContactInfo";
import Map from "../components/Map";

interface ContactPageProps {};

export default function ContactPage(props: ContactPageProps) {
    const language = useLanguage();

    return <>
        <Header coverText={language.menu.contact} home={false}/>
        <ContactInfo />
        <Map />
        <Footer />
    </>
}