import { Box } from "@mui/material"

interface MapProps {};

const styles = {
    box: {
        justifyContent: "center",
        textAlign: "center",
        paddingBottom: 6
    },
    image: {
        height: "100%",
        width: "100%"
    }
}
export default function Map(props: MapProps) {
    return <Box sx={styles.box} width="100%">
        <Box width={{xs: "100%", md: "60%"}} margin="auto">
            <img 
                src={window.location.origin + window.location.pathname + "maps.png"} 
                style={styles.image}
                alt="address"
            />
        </Box>
    </Box>
}