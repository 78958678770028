import { Box, Theme, useTheme, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { useLanguage } from "../../../../state/language";

interface PortfolioHeaderProps {
    checkStyle: any
    stickyStyle: any,
    workCategories: Array<any>
};

const makeStyles = (theme: Theme) => {
    return {
        header: {
            backgroundColor: "transparent",
            borderStyle: "none",
            overflow: "visible",
            verticalAlign: "bottom",
            padding: 0,
            zIndex: 0,
            height: "200px"
        },
        headerBox: {
            whiteSpace: "nowrap",
            overflow: "visible",
            backgroundColor: "white",
            borderStyle: "none",
            zIndex: 3,
            height: 0,
            bottom: 0
        },
        headerText: {
            textAlign: "flex-start",
            zIndex: 3000,
            overflow: "visible",
            transformOrigin: "left",
            transform: "rotate(315deg) translate(40px, -30px)",
        }
    }
}

export default function PortfolioHeader(props: PortfolioHeaderProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <TableHead  >
        <TableRow sx={{overflow: "scroll"}}>
            <TableCell sx={[props.stickyStyle, styles.header, {zIndex: 5000}]}></TableCell>
            <TableCell sx={[styles.header, props.checkStyle]} >
                <Box sx={[styles.headerBox]}><Typography sx={styles.headerText}>{language.about.portfolio.geography}</Typography></Box>
            </TableCell>
            {
                props.workCategories.map((value: string) => {
                    return <TableCell sx={[styles.header, props.checkStyle]} key={value}>
                        <Box sx={[styles.headerBox]}>
                            <Typography sx={styles.headerText}>
                                {(language.about.portfolio.categories as any)[value]}
                            </Typography>
                        </Box>
                    </TableCell>
                })
            }
        </TableRow>
    </TableHead>
}