import './App.css';
import { RecoilRoot } from 'recoil';
import { Router } from './features/router';
import { ThemeProvider } from '@mui/material';
import HttpsRedirect from "react-https-redirect";
import theme from './theme/Theme';

function App() {
  return <HttpsRedirect>
      <RecoilRoot>
      <ThemeProvider theme={theme}>
        <Router />
      </ThemeProvider>
    </RecoilRoot>
  </HttpsRedirect>
};

export default App;
