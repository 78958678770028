import { Box, Grid } from "@mui/material";
import Service from "./Service";
import useServices from "../../services/data/services";

interface ServicesProps {};

const styles = {
    container: {
        justifyContent: "space-evenly",
    },
    item: {}
};

export default function Services(props: ServicesProps) {
    const services = useServices();
    

    return <Box px={{xs: 2, sm: 2, md: 2, lg: 8, xl: 12}} style={styles.container} mt={{xs: 2, md: -8}}>
        <Grid 
            container 
            rowSpacing={0}
            columnSpacing={8}
            alignItems="stretch"
        >
            {
                services.map((service, index) => {
                    return <Service 
                        index={index + 1} 
                        key={service.name} 
                        name={service.name as any} 
                        image={service.photo} 
                    />
                })
            }
        </Grid>
    </Box>
}