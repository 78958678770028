import { Box, Fade, Theme, Typography, useTheme } from "@mui/material";

interface PageCoverProps {
    coverText: string
    large: boolean
};

const makeStyles = (theme: Theme, large: boolean) => {
    return {
        box: {
            backgroundColor: theme.palette.primary.main,
        },
        text: {
            fontSize: large ? 36 : 24,
            color: theme.palette.primary.light,
            whiteSpace: "pre-line",
            textAlign: "center"
        }
    }
};

export default function PageCover(props: PageCoverProps) {
    const theme = useTheme();
    const styles = makeStyles(theme, props.large);
    
    return <Box px={{xs: 4, md: "30%"}} py={{xs: 4, md: 14}} sx={styles.box}>
        <Fade in={true} timeout={2000}><Typography sx={styles.text}>{props.coverText}</Typography></Fade>
    </Box>
}