import Language from "./Language";

const en: Language = {
    menu: {
        home: "Home",
        leatrop: "Leatrop",
        services: "Services",
        about: "About us",
        contact: "Contact"
    },
    home: {
        intro: (
            "Leatrop Management offers Interim Management services focussed on Change Management, Procurement and Sustainability. On both support and leadership level we help your organisation to perform better and more efficiently.\n\n" + 
            "The focus?\n" + 
            "Drive for improvement and adaptation to the changing world."
        ),
        contact: "Get in touch"
    },
    services: {
        learnMore: "Learn more",
        changeManagement: {
            title: "Change management",
            short: "We offer change management on a deeper level, which means that every act will provoke change throughout the whole structure & strategy of the organisation.",
            long: "Change Management focuses on the people side of change. " + 
                  "How often doesn't it occur that big change projects are not supported by the users or the organisation?  Your teams want to understand what’s in it for them. " + 
                  "They wonder how it will impact their jobs and what the need for the change is. In order to take away this resilience, Leatrop management will guide you through.",
            beforeSubtitle: "Philip, as certified PROSCI-ADKAR change practitioner, will help you in any change project by finding the right translation for your team",
            subtitle1: "Where to start?",
            bullets: [
                {
                    title: "Analyse as is",
                    items: []
                },
                {
                    title: "Desire to be",
                    items: []
                },
                {
                    title: "Map different steps and what actions are necessary in the ADKAR process",
                    items: []
                }
            ],
            afterSubtitle: "Every change hurts. Somewhere in the organistaion more than somewhere else. But that should not be seen as an obstacle. Change is a chance. Change is a choice."
        },
        procurement: {
            title: "Procurement",
            short: "Leatrop Management is your partner and temporary solution for all your procurement issues, questions and teams, both when starting up or up and running.",
            long: "Equipped with long and broad experience, internationally and in multiple industries.",
            subtitle1: "Our offer",
            bullets: [
                {
                    title: "Procurement strategy",
                    items: [
                        "Roadmap",
                        "Launch",
                        "Implementation",
                        "Keep track"
                    ]
                },
                {
                    title: "Procurement teams",
                    items: [
                        "Organisation & reorganistaion",
                        "Revitalisation",
                        "Positioning",
                        "Training & coaching of young professionals and experienced leaders"
                    ]
                },
                {
                    title: "Procurement issues",
                    items: [
                        "Project rescue",
                        "Staffing gaps",
                        "Prevention"
                    ]
                }
            ]
        },
        sustainability: {
            title: "Sustainability",
            short: "Sustainability is key nowadays, on all levels of your organisation. We help you introduce, implement and adopt sustainability in your business",
            long: "Also overwhelmed by the new reality: scope 1-2 & 3, the SDG's, ESG's, CO₂-emission and the circular economy? \nSustainability is not to be ignored any longer. It's key nowadays, on all levels of your organisation: In your business approach, processes and supply & value chain. Leatrop Management, as certified sustainability practitioner helps you to introduce and implement sustainability, making sure that your business is getting ready and well prepared for this new way of running your operations and your business",
            subtitle1: "Where to start?",
            bullets: [
                {
                    title: "Mapping scope 1 and 2",
                    items: []
                },
                {
                    title: "Mapping scope 3 in close collaboration with all stakeholders",
                    items: []
                },
                {
                    title: "Mapping 3 levels of actions  (different impact levels)",
                    items: [
                        "Direct impact",
                        "Indirect impact actions",
                        "Transformational impact actions"
                    ]
                },
                {
                    title: "Development of a communication plan for both internal and external stakeholders",
                    items: [
                        // "Assistance in shaping your process approach from raw materials to your waste streams",
                        // "Scope 1 & 2",
                        // "Working together with your suppliers / partners in order to reduce your scope 3"
                    ]
                }
            ]
        }
    },
    about: {
        intro: (
            "Philip Portael is an experienced people and team manager. " +
            "Through the years, he has built an extensive experience in procurement, change and sustainability management in multiple industries and different types of companies, from medium-sized to multinational, on both regional and global scale.\n\nHis know-how and perfect understanding of complex industrial and commercial processes in general are based on his working experience as an employee and interim manager with leading companies in their sector."
        ),
        portfolioReference: "More details in the portfolio below.",
        competences: {
            title: "Competences",
            items: [
                "Delivering savings by e.g. optimising cost structures",
                "Understanding the importance of good relations with internal and external stakeholders",
                "Inspiring leadership, recognized by team members",
                "Building and leading international and decentralised teams",
                "Creating portfolio analysis, supplier’s market analysis and development of different approaches for each type of market",
                "Developing, implementing and executing long term company, purchase and commercial strategies",
                "Creating and using synergies across different departments and (international) plants",
                "Advocating as an experienced and pragmatic change agent",
                "People side of change, understanding different profiles of team members and their competences",
                "Support in implementing the chosen ESG’s by actively working together with your organisation and your external parnters",
                "Implementing LEAN management techniques in (purchase/supply chain) administration departments"
            ],
        },
        portfolio: {
            title: "Portfolio",
            categories: {
                permanentRole: "Permanent role",
                interimManagement: "Interim Management",
                procurement: "Procurement",
                businessDevelopment: "Business Development",
                designCreateStructure: "Design - Creation - Structure",
                supplyChain: "Supply Chain",
                changeManagement: "Change Management",
                projectManagement: "Project Management",
                coachingAndTraining: "Coaching & Training",
                sustainability: "Sustainability",
                staffing: "Staffing",
                advisor: "Advice",
                benelux: "Benelux",
                europe: "Europe",
                global: "Global"
            },
            contractType: {
                permanent: "Permanent",
                contract: "Contract",
                header: "Employment"
            },
            geography: "Geography"
        }
    },
    contact: {
        address: "Address",
        phone: "Phone",
        email: "Email",
        vat: "VAT"
    }
};

export default en;