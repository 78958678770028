import { Box, Button, Theme, useTheme } from "@mui/material";
import { useLanguage } from "../../../state/language";
import { useNavigate } from "react-router-dom";

interface ContactButtonProps {};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            width: "100%",
            textAlign: "center",
            alignContent: "center",
        },
        button: {
            color: theme.palette.warning.main,
            backgroundColor: theme.palette.primary.light,
            fontWeight: "bold",
            p: 2,
            borderRadius: 5,
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.light
            },
            marginBottom: 12
        }
    }
};

export default function ContactButton(props: ContactButtonProps) {
    const language = useLanguage();
    const theme = useTheme();
    const styles = makeStyles(theme);
    const navigate = useNavigate();

    return <Box sx={styles.box} pb={{xs: 2, md: 10}}>
        <Button sx={styles.button} onClick={() => navigate("/contact")}>{language.home.contact}</Button>
    </Box>
}