import { Box, Fade, Theme, Typography, useTheme } from "@mui/material";
import ContactButton from "../../features/home/components/ContactButton";

interface PageCoverProps {
    large: boolean
    coverText: string
};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            backgroundColor: "black",
            position: "relative",
            flexDirection: "column",
            textAlign: "center",
            overflow: "hidden",
            display: { xs: 'none', md: 'flex' },

        },
        image: {
            width: "100%",
            opacity: 0.2,
            margin: "-300px 0 -300px 0",
            minHeight: "1300px"
        },
        text: {
            fontSize: 24,
            color: theme.palette.primary.light,
            whiteSpace: "pre-line",
            textAlign: "center",
            paddingBottom: "50px"
        },
        textBox: {
            minHeight: "600px",
            justifyContent: "center",
            position: "absolute",
            top: "20%",
            opacity: 1,
            width: "100%"
        }
    }
};

export default function PageCoverWithPhoto(props: PageCoverProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    
    return <Box sx={styles.box}>
        <img 
            src={require("../../assets/changeManagementPrevious.jpg")}
            alt="cover"
            style={styles.image}
            sizes="contain"/>
        <Box sx={styles.textBox}>
            <Fade in={true} timeout={2000} ><Typography sx={styles.text} px={{xs: 8, md: 16, lg: 24, xl: 36}}>{props.coverText}</Typography></Fade>
            <ContactButton />
        </Box>

    </Box>
}