import { Box, Theme, useTheme } from "@mui/material";
import HeaderBarWithLinks from "./HeaderBarWithLinks";
import PageCover from "./PageCover";
import PageCoverWithPhoto from "./PageCoverWithPhoto";

interface HeaderProps {
    coverText: string
    children?: JSX.Element | JSX.Element[]
    home?: boolean
};


const makeStyles = (theme: Theme) => {
    return {
        box: {
            boxShadow: 5,
            backgroundColor: theme.palette.primary.main
        }
    }
}
export default function Header(props: HeaderProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);

    return <Box sx={styles.box}>
        <HeaderBarWithLinks />
        {
            props.home ? <>
                <PageCoverWithPhoto large coverText={props.coverText}/>
                <Box sx={{display: { xs: 'flex', md: 'none' }}}>
                    <PageCover coverText={props.coverText} large={false}/>
                </Box>
            </> : 
            <PageCover coverText={props.coverText} large={true}/>}
    </Box>
}