import { Box, Divider, Theme, Typography, useTheme } from "@mui/material";
import { useLanguage } from "../../state/language";
import { useNavigate } from "react-router-dom";

interface LogoProps { };

const makeStyles = (theme: Theme) => {
    return {
        box: {
            display: "flex", 
            flexDirection: "row",
            backgroundColor: theme.palette.primary.main,
            padding: 1,
            paddingX: 4,
            borderRadius: 2,
            alignItems: "center",
            cursor: "pointer",
        },
        leatrop: {
            color: theme.palette.primary.light,
            padding: 0.5,
            fontWeight: "bold",
            fontSize: 24
        },
        divider: {
            backgroundColor: theme.palette.primary.light,
            marginX: 2
        },
        management: {
            display: "block",
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.primary.light,
            padding: 0.5,
            paddingX: 2
        }
    }
}
export default function Logo(props: LogoProps) {
    const language = useLanguage();
    const theme = useTheme();
    const styles = makeStyles(theme);
    const navigate = useNavigate();

    //return <img width="100%" src={window.location.origin + "/LeatropManagement.jpg"} />
    return <Box sx={styles.box} onClick={() => {navigate("/")}}>
        <Typography sx={styles.leatrop}>{language.menu.leatrop}</Typography>
        <Divider orientation="vertical" flexItem sx={styles.divider}/>
        <Typography sx={styles.management}>{"management"}</Typography>
    </Box>
}