export const phone = "+32 (0)499 67 35 00"
export const email = "philip@leatrop.be"
export const vat = "BE 0819 463 918"
export const company = "Leatrop Management BV";
export const linkedInLink = "https://www.linkedin.com/in/philip-portael-5aaba1a/"
export const linkedIn = "Philip Portael"
export const address = {
    street: "Medelaar 103",
    city: "2980 Zoersel",
    region: "Antwerp",
    country: "Belgium"
};

