import { Box, Grid, Theme, Typography, useTheme } from "@mui/material";
import { useLanguage } from "../../../state/language";
import Photo from "../../../components/photo/Photo";

interface AboutProps {
    includePortfolioReference?: boolean
};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            py: 8,
        },
        title: {
            fontSize: 48,
            paddingBottom: 8
        },
        textWithPhotoBox: {
            alignItems: "center",
            justifyContent: "space-between"
        },
        textBox: {
        },
        text: {
            fontSize: 20,
            whiteSpace: "pre-line"
        },
        photoBox: {

        },
        photo: {

        }
    }
};

export default function About(props: AboutProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <Box sx={styles.box} my={{sx: 2, md: 4}} px={{xs: 2, md: 8, lg: 48}}>
        <Grid container direction="row" sx={styles.textWithPhotoBox} rowSpacing={5}>
            <Grid item xs={10} md={8}>
                <Typography sx={styles.title}>{language.menu.about}</Typography>
            </Grid>
        </Grid>
        <Grid container direction="row" sx={styles.textWithPhotoBox} rowSpacing={5} columnSpacing={3}>
            <Grid item sx={styles.textBox} xs={12} md={8} p={{xs: 0, md: 0}}>
                <Typography sx={styles.text}>{language.about.intro}</Typography>
                {props.includePortfolioReference ? <Typography sx={styles.text}>{"\n" + language.about.portfolioReference}</Typography> : <></>}

            </Grid>
            <Grid item sx={styles.photoBox} xs={12} md={4} lg={3}>
                <Photo />
            </Grid>
        </Grid>
    </Box>
}