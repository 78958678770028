import { Box } from "@mui/material";

interface PhotoProps { };

const styles = {
    box: {
        borderRadius: 4
    },
    image: {
        width: "100%",
        height: "100%",
        borderRadius: 20
    }
}
export default function Photo(props: PhotoProps) {
    return <Box sx={styles.box}>
        <img 
            src={window.location.origin + window.location.pathname + "photo.jpeg"}
            style={styles.image}
            alt="Philip"
        />
    </Box>
}