import { LocalPhone, Mail, PinDrop, Home, Language, LinkedIn } from "@mui/icons-material";
import { address, email, linkedIn, linkedInLink, phone } from "../../../state/data";
import { Box, Grid, Link, Theme, Typography, useTheme } from "@mui/material";

interface ContactInfoProps {};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            width: "100%",
            textAlign: "center",
            justifyContent: "center"
        },
        item: {
            color: theme.palette.primary.dark,
            fontSize: 24
        },
        icon: {
            color: theme.palette.primary.dark,
            fontSize: 24,
            marginRight: 3
        },
        iconTextBox: {
            display: "flex",
            flexGrow: 0,
            flexDirection: "row",
            alignItems: "center",
        },
        link: {
            textDecoration: "none"
        }
    }
}

export default function ContactInfo(props: ContactInfoProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);

    return <Grid container direction="row" sx={styles.box} py={{xs: 2, md: 8}}>
        <Grid item xs={12} md={4}>
            <Box display={{xs: "block", md: "inline-block"}}>
                <Box sx={styles.iconTextBox}><LocalPhone sx={styles.icon}/><Typography sx={styles.item}>{phone}</Typography></Box>
                <Box sx={styles.iconTextBox}><Mail sx={styles.icon}/>
                    <Link href={"mailto:" + email} sx={styles.link} underline="none">
                        <Typography sx={styles.item}>{email}</Typography>
                    </Link>
                    
                </Box>
                <Box sx={styles.iconTextBox}><LinkedIn sx={styles.icon}/>
                    <Link href={linkedInLink} sx={styles.link} underline="none">
                        <Typography sx={styles.item}>{linkedIn}</Typography>
                    </Link>
                    
                </Box>
            </Box>
        </Grid>
        <Grid item xs={12} md={5}>
            <Box display={{xs: "block", md: "inline-block"}}>
                <Box sx={styles.iconTextBox}><PinDrop sx={styles.icon}/><Typography sx={styles.item}>{address.street}</Typography></Box>
                <Box sx={styles.iconTextBox}><Home sx={styles.icon}/><Typography sx={styles.item}>{address.city}</Typography></Box>
                <Box sx={styles.iconTextBox}><Language sx={styles.icon}/><Typography sx={styles.item}>{address.country}</Typography></Box>
            </Box>
        </Grid>
    </Grid>
}