import { Theme, useTheme, Box, Grid, Typography, Divider, Link } from "@mui/material"
import { useLanguage } from "../../state/language";
import { address, company, email, linkedIn, linkedInLink, phone } from "../../state/data";
import Logo from "../logo/Logo";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MailIcon from '@mui/icons-material/Mail';
import PinDropIcon from '@mui/icons-material/PinDrop';
import HomeIcon from '@mui/icons-material/Home';
import LanguageIcon from '@mui/icons-material/Language';
import { LinkedIn } from "@mui/icons-material";

interface FooterProps {};

const makeStyles = (theme: Theme) => {
    return {
        box: {
            backgroundColor: theme.palette.primary.main,
        },
        grid: {

        },
        item: {
            color: theme.palette.primary.light
        },
        iconTextBox: {
            color: theme.palette.primary.light,
            display: "flex",
            flexDirection: "row",
        },
        icon: {
            marginRight: 2,
            fontSize: 18
        },
        divider: {
            backgroundColor: theme.palette.primary.light,
            my:2,
            width: "60%"
        },
        footerTitle: {
            color: "white",
            fontSize: 18,
            fontWeight: "bold"
        },
        footerItem: {
            color: "white"
        }
    }
}
export default function Footer(props: FooterProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);
    const language = useLanguage();

    return <Box sx={styles.box} px={{xs: 2, md: 12}} py={{xs: 2, md: 6}}>
        <Grid container sx={styles.grid} rowSpacing={4}>
            <Grid item xs={12} md={4} sx={styles.item}>
                <Typography sx={styles.footerTitle}>{language.menu.contact}</Typography>
                <Divider sx={styles.divider}/>
                <Box sx={styles.iconTextBox}><LocalPhoneIcon sx={styles.icon}/><Typography sx={styles.footerItem}>{phone}</Typography></Box>
                <Box sx={styles.iconTextBox}><MailIcon sx={styles.icon}/>
                    <Link href={"mailto:" + email} underline="none">
                        <Typography sx={styles.footerItem}>{email}</Typography>
                    </Link>
                </Box>
                <Box sx={styles.iconTextBox}><LinkedIn sx={styles.icon}/>
                <Link href={linkedInLink} underline="none">
                    <Typography sx={styles.item}>{linkedIn}</Typography>
                </Link>
                
            </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.item}>
                <Typography sx={styles.footerTitle}>{company}</Typography>
                <Divider sx={styles.divider}/>
                <Box sx={styles.iconTextBox}><PinDropIcon sx={styles.icon}/><Typography sx={styles.footerItem}>{address.street}</Typography></Box>
                <Box sx={styles.iconTextBox}><HomeIcon sx={styles.icon}/><Typography sx={styles.footerItem}>{address.city}</Typography></Box>
                <Box sx={styles.iconTextBox}><LanguageIcon sx={styles.icon}/><Typography sx={styles.footerItem}>{address.country}</Typography></Box>
            </Grid>
            <Grid item xs={12} md={4} sx={styles.item}>
                <Logo />
            </Grid>
        </Grid>
    </Box>
}