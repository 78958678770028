import Language from "../../language/Language";

interface PortfolioItem {
    name: string
    categories: Array<keyof Language["about"]["portfolio"]["categories"]>
}

const portfolio: Array<PortfolioItem> = [
    {
        name: "Ajinomoto Agrosolutions",
        categories: ["interimManagement", "procurement", "coachingAndTraining", "advisor", "europe", "sustainability"]
    },
    {
        name: "Avient (Polyone SA)",
        categories: ["interimManagement", "procurement", "designCreateStructure", "supplyChain", "coachingAndTraining", "staffing", "changeManagement", "benelux", "europe", "global"]
    },
    {
        name: "Beaulieu International Group",
        categories: ["interimManagement", "procurement", "supplyChain", "changeManagement", "benelux", "europe", "global"]
    },
    {
        name: "Bionerga - Biostoom",
        categories: ["interimManagement", "procurement", "designCreateStructure", "businessDevelopment", "sustainability", "advisor", "benelux", "europe"]
    },
    {
        name: "Cofco International",
        categories: ["interimManagement", "procurement", "designCreateStructure", "staffing", "coachingAndTraining", "changeManagement", "benelux", "europe"]
    },
    {
        name: "Derbigum NV",
        categories: ["interimManagement", "procurement", "coachingAndTraining", "benelux", "europe"]
    },
    {
        name: "Dossche Mills",
        categories: ["interimManagement", "procurement", "sustainability", "benelux"]
    },
    {
        name: "Eastman NV",
        categories: ["interimManagement", "procurement", "designCreateStructure", "supplyChain", "coachingAndTraining", "benelux", "europe", "global"]
    },
    {
        name: "Ferring Pharmaceuticals",
        categories: ["interimManagement", "procurement", "projectManagement", "advisor", "europe"]
    },
    {
        name: "Huntsman",
        categories: ["permanentRole", "procurement", "designCreateStructure", "staffing", "coachingAndTraining", "benelux", "europe", "global"]
    },
    {
        name: "Hyplast NV",
        categories: ["permanentRole", "businessDevelopment", "benelux"]
    },
    {
        name: "IGM Resins BV",
        categories: ["interimManagement", "procurement", "designCreateStructure", "supplyChain", "staffing", "coachingAndTraining", "benelux", "europe", "global"]
    },
    {
        name: "Kaneka NV",
        categories: ["interimManagement", "procurement", "coachingAndTraining",  "changeManagement", "benelux", "europe", "global"]
    },
    {
        name: "Messer NV",
        categories: ["interimManagement", "advisor", "benelux"]
    },
    {
        name: "Pears Plastics (Watco-Sita-Suez) NV",
        categories: ["permanentRole", "procurement", "designCreateStructure", "supplyChain", "businessDevelopment", "sustainability", "coachingAndTraining", "changeManagement", "benelux", "europe", "global"]
    },
    {
        name: "Performance Additives",
        categories: ["interimManagement", "procurement", "supplyChain", "coachingAndTraining", "projectManagement", "benelux", "europe", "global"]
    },
    {
        name: "Recticel NV",
        categories: ["interimManagement", "procurement", "coachingAndTraining", "advisor", "benelux", "europe"]
    },
    {
        name: "SCK CEN",
        categories: ["interimManagement", "procurement", "designCreateStructure", "supplyChain", "coachingAndTraining", "staffing", "changeManagement", "projectManagement", "benelux", "europe", "global"]
    },
    {
        name: "Soudal",
        categories: ["permanentRole", "procurement", "designCreateStructure", "staffing", "supplyChain", "benelux", "europe", "global"]
    },
    {
        name: "TDS Automotive (Syncreon) NV",
        categories: ["interimManagement", "procurement", "designCreateStructure", "staffing", "coachingAndTraining", "changeManagement", "benelux", "europe"]
    },
    {
        name: "Total NV",
        categories: ["interimManagement", "procurement", "coachingAndTraining", "advisor", "benelux", "europe", "global"]
    },
    {
        name: "Water-Link (Induss) (AWW) NV",
        categories: ["interimManagement", "advisor", "benelux"]
    }
]

export default portfolio;