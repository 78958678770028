import { MultipleStop, Park, PublishedWithChanges } from "@mui/icons-material";
import { useLanguage } from "../../../state/language"


const useServices = () => {
    const language = useLanguage();
    const services = [
        {
            ...language.services.changeManagement,
            icon: PublishedWithChanges,
            name: "changeManagement",
            photo: "changeManagement.jpeg",
            sidePhoto: "changeManagementBlocks.jpeg"
        },
        {
            ...language.services.procurement,
            icon: MultipleStop,
            reverse: true,
            name: "procurement",
            photo: "procurement.jpg",
            sidePhoto: "procurement.jpg"
        },
        {
            ...language.services.sustainability,
            icon: Park,
            name: "sustainability",
            photo: "sustainability.jpg",
            sidePhoto: "sustainability.jpg"
        }
    ];
    return services;
}

export default useServices;