import Footer from "../../../components/footer/Footer";
import Header from "../../../components/header/Header";
import { useLanguage } from "../../../state/language";
import About from "../../home/components/About";
import Competences from "../components/Competences";
import Portfolio from "../components/Portfolio";

interface AboutPageProps {};

export default function AboutPage(props: AboutPageProps) {
    const language = useLanguage();

    return <>
        <Header coverText={language.menu.about} home={false}/>
        <About includePortfolioReference/>
        <Competences />
        <Portfolio />
        <Footer />
    </>
}