import { TableCell, TableRow, Theme, useTheme, Typography } from "@mui/material"

interface EmploymentRowProps {
    name: string
    span: number
};

const makeStyles = (theme: Theme) => {
    return {
        row: {
            backgroundColor: theme.palette.primary.main,
        },
        text: {
            color: theme.palette.primary.light
        }
    }
}
export default function EmploymentRow(props: EmploymentRowProps) {
    const theme = useTheme();
    const styles = makeStyles(theme);

    return <TableRow key={props.name} sx={styles.row} >
        <TableCell colSpan={props.span}>
            <Typography sx={styles.text}>{props.name}</Typography>
        </TableCell>
    </TableRow>
}