import { AppBar, Container, Toolbar } from "@mui/material";
import { useState, MouseEvent } from "react";
import HeaderLarge from "./HeaderLarge";
import HeaderSmall from "./HeaderSmall";

interface HeaderBarWithLinksProps {};

export default function HeaderBarWithLinks(props: HeaderBarWithLinksProps) {
    const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  
    const handleOpenNavMenu = (event: MouseEvent<HTMLElement>) => {
      setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
      setAnchorElNav(null);
    };

    return  <AppBar position="static">
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        
        <HeaderSmall
          menuAnchor={anchorElNav}
          closeMenu={handleCloseNavMenu} 
          openMenu={handleOpenNavMenu}
        />
        <HeaderLarge closeMenu={handleCloseNavMenu} />
      </Toolbar>
    </Container>
  </AppBar>
}